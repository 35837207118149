<template>
  <screen_width_cont>
    <div class="myBodyGeneral" id="editDesnatario">
      <div class="myHeader_vertical" ref="myHeader_editDesnatario">
        <div class="cont_300_basico ancho_acotado">
          <div class="header_top">
            <headerBack @back="backLocal" />
          </div>
          <div class="editDesnatarioTitle title">{{ title }}</div>
          <div class="editDesnatario_sub_Title sub_title">{{ sub_title }}</div>
        </div>
      </div>

      <blurComp
        class="blur_sup"
        :style="blur_sup_editDesnatario"
        :scroll_to_="scroll_to_top"
        tipo="sup"
      />

      <div
        class="contenido centrado"
        :style="contenido_editDesnatario"
        v-show="cont_visible"
        @scroll="onScroll"
        ref="contenido_editDesnatario"
      >
        <div class="cont_300_basico ancho_acotado">
          <div class="editDesnatarioBody">
            <div
              v-for="(elemento, index) in listaElementosDomOrdenada"
              :key="index"
              :ref="elemento.variable"
            >
              <div
                class="editDesnatarioInputCont"
                v-if="
                  elemento.tipo == 'selectPais' &&
                  elemento.elementos &&
                  elemento.elementos.length > 0
                "
              >
                <selectPaisDinamicComp
                  :elementosDom="elemento"
                  @selectPais="cambioElementoLocal"
                  :indiceElementoDom="index"
                  :mensajeError="
                    t('configuracion.data.textos_errores.campoObligatorio')
                  "
                  :disabled="false"
                  tabindex="0"
                  :error="elemento.tieneError"
                  :primeraCarga="primeraCarga"
                  :config="paisEditable"
                  :forzarPrimerEmit="false"
                  @valida_basico_="valida_basico"
                />
              </div>

              <div
                class="editDesnatarioInputCont"
                v-if="
                  elemento.tipo == 'select' && elemento.elementos.length > 0
                "
              >
                <selectBasico
                  :elementosDom="elemento"
                  @llamarCambioElemento="cambioElementoLocal"
                  :mensajeError="
                    t('configuracion.data.textos_errores.campoObligatorio')
                  "
                  :disabled="false"
                  tabindex="0"
                  :error="elemento.tieneError"
                  @valida_basico_="valida_basico"
                  :id="index"
                  :registrando="registrando"
                />
              </div>

              <div
                class="editDesnatarioInputCont"
                v-if="elemento.tipo == 'text' || elemento.tipo == 'number'"
              >
                <inputBoxComp
                  :typeInput="elemento.tipo"
                  :error="elemento.tieneError"
                  @llamarCambioElementoInput="cambioElementoLocal"
                  :mensajeError="[
                    t('configuracion.data.textos_errores.campoObligatorio'),
                    '',
                  ]"
                  :mensajeOk="{ mostrar: false, texto: '' }"
                  :elementosDom="elemento"
                  :indiceElementoDom="index"
                  @valida_basico_="valida_basico"
                />
              </div>

              <div
                class="editDesnatarioInputCont"
                v-if="elemento.tipo == 'tel'"
              >
                <inputBasicoDinamicComp_tel
                  :typeInput="elemento.tipo"
                  :error="elemento.tieneError"
                  @llamarCambioElementoInput="cambioElementoLocal"
                  :mensajeError="[
                    t('configuracion.data.textos_errores.campoObligatorio'),
                    '',
                  ]"
                  :mensajeOk="{ mostrar: false, texto: '' }"
                  :elementosDom="elemento"
                  :indiceElementoDom="index"
                  @valida_basico_="valida_basico"
                />
              </div>

              <div
                class="editDesnatarioInputCont"
                v-if="
                  elemento.padre_ok &&
                  (elemento.tipo == 'text_hijo' ||
                    elemento.tipo == 'tel_hijo' ||
                    elemento.tipo == 'number_hijo')
                "
              >
                <inputBasicoComp
                  :typeInput="elemento.tipo"
                  :error="elemento.tieneError"
                  @llamarCambioElementoInput="cambioElementoLocal"
                  :mensajeError="[
                    t('configuracion.data.textos_errores.campoObligatorio'),
                    '',
                  ]"
                  :mensajeOk="{ mostrar: false, texto: '' }"
                  :elementosDom="elemento"
                  :indiceElementoDom="index"
                  @valida_basico_="valida_basico"
                />
              </div>
            </div>
          </div>

          <div
            class=""
            :class="
              this.dataDestinSelected.tipo == 'nueva_forma_entrega'
                ? 'editDesnatarioBtn_fondo'
                : 'editDesnatarioBtn'
            "
          >
            <btnAction
              :textBtn="t('app.comunes.btns.guardar')"
              :disabled="!btnActive"
              @action="continuarRegistro"
              colorBack="azul"
              colorText="blco"
              :estadoBtn="estadoBtn"
              :msg_completar="t('app.comunes.btns.completar_formulario')"
            />
          </div>
        </div>
      </div>

      <blurComp
        class="blur_inf"
        :style="blur_inf_editDesnatario"
        :scroll_to_="scroll_to_bottom"
        tipo="inf"
      />

      <div class="footer" ref="footer_editDesnatario">
        <div class="editDesnatarioFooter">
          <enviosSeguros @callEnvioSeguroModal_="callEnvioSeguroModal" />
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
import { modalController } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import headerBack from "../components/generales/headerBack.vue";
import btnAction from "../components/generales/btnAction.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import selectPaisDinamicComp from "../components/destinatario/selectPaisDinamicComp.vue";
import selectBasico from "../components/destinatario/selectBasicoDinamicComp.vue";
import inputBoxComp from "../components/destinatario/inputBasicoDinamicComp.vue";
import inputBasicoComp from "../components/destinatario/inputBasicoDinamicComp.vue";
import inputBasicoDinamicComp_tel from "../components/destinatario/inputBasicoDinamicComp_tel.vue";
import envioSeguroHome from "./modales/enviosSegurosHome.vue";
import enviosSeguros from "../components/generales/envios_seguros.vue";
import blurComp from "../components/generales/blur.vue";
import {
  blur_sup_scroll,
  blur_inf_scroll,
  contenido_style,
  blur_sup_style,
  blur_inf_style,
} from "../assets/funciones/blur";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "destinMyDataForm",
  components: {
    headerBack,
    btnAction,
    selectPaisDinamicComp,
    selectBasico,
    inputBoxComp,
    inputBasicoComp,
    inputBasicoDinamicComp_tel,
    enviosSeguros,
    blurComp,
    screen_width_cont,
  },
  data() {
    return {
      niveles: 0,
      nivelActual: 0,
      enVista: true,
      primeraCarga: true,
      btnActive: true,
      edit_destinatario: null,
      header: 0,
      footer: 0,
      cont_visible: false,
      obj_valida_basico: {},
      scroll_to_bottom: true,
      scroll_to_top: true,
      contenido_editDesnatario: {},
      blur_sup_editDesnatario: {},
      blur_inf_editDesnatario: {},
      variable_con_error: "",
      estado_validaciones: false,
      registrando: false,
    };
  },
  computed: {
    ...mapState([
      "configuracion",
      "listaElementosDom",
      "listaElementosDomTemp",
      "infoNodos",
      "remitente",
      "procesoGeneral",
      "transaccion",
      "dataDestinSelected",
      "menuSpDesde",
      "desde",
      "heigthScreen",
      "widthScreen",
      "respBeneficiarioModif",
      "plataforma",
      "resp_destinatario_update",
      "resp_forma_entrega_update",
      "resp_forma_entrega_reg",
      "datos_consultados",
    ]),
    ...mapGetters([]),

    listaElementosDomOrdenada() {
      let aux = [];
      let proceso = "";
      if (
        this.procesoGeneral == "registro" ||
        this.procesoGeneral == "cotizador" ||
        this.procesoGeneral == "ingresar_giro"
      )
        proceso = "reg";
      if (this.procesoGeneral == "misDatos") proceso = "edit";
      if (this.listaElementosDom.length > 0 && this.enVista) {
        this.configuracion.reglasFormularioDestinatario.forEach(
          async (item) => {
            if (
              proceso != "" &&
              item[15][proceso] &&
              item[15][proceso][0] == "si"
            ) {
              this.listaElementosDom.forEach((elemento) => {
                if (elemento.variable == item[4]) {
                  if (elemento.variable == "region_caja") {
                    let new_elementos = {};
                    elemento.elementos.forEach((opcion) => {
                      if (!new_elementos[opcion.value]) {
                        new_elementos[opcion.value] = {};
                        new_elementos[opcion.value] = opcion;
                      }
                    });
                    let new_elementos_array = Object.entries(new_elementos);
                    elemento.elementos = [];
                    new_elementos_array.forEach((element) => {
                      elemento.elementos.push(element[1]);
                    });
                    elemento.editable = item[15][proceso][1];
                    aux.push(elemento);
                  } else if (elemento.variable == "comuna_caja") {
                    let new_elementos = {};
                    elemento.elementos.forEach((opcion) => {
                      if (!new_elementos[opcion.value]) {
                        new_elementos[opcion.value] = {};
                        new_elementos[opcion.value] = opcion;
                      }
                    });
                    let new_elementos_array = Object.entries(new_elementos);
                    elemento.elementos = [];
                    new_elementos_array.forEach((element) => {
                      elemento.elementos.push(element[1]);
                    });
                    elemento.editable = item[15][proceso][1];
                    aux.push(elemento);
                  } else if (elemento.variable == "banco_caja") {
                    let new_elementos = {};
                    elemento.elementos.forEach((opcion) => {
                      if (!new_elementos[opcion.value]) {
                        new_elementos[opcion.value] = {};
                        new_elementos[opcion.value] = opcion;
                      }
                    });
                    let new_elementos_array = Object.entries(new_elementos);
                    elemento.elementos = [];
                    new_elementos_array.forEach((element) => {
                      elemento.elementos.push(element[1]);
                    });
                    elemento.editable = item[15][proceso][1];
                    aux.push(elemento);
                  } else if (elemento.variable == "tipo_cuenta") {
                    if (this.procesoGeneral == "cotizador") {
                      let new_elementos = {};

                      elemento.elementos.forEach((opcion) => {
                        let tipos_cuenta_habiles = {};
                        for (
                          let index = 0;
                          index <
                          this.configuracion.pais[this.transaccion.pais_destino]
                            .tipo_cuenta.length;
                          index++
                        ) {
                          const cuenta =
                            this.configuracion.pais[
                              this.transaccion.pais_destino
                            ].tipo_cuenta[index];
                          if (cuenta[2] == this.transaccion.moneda_destino_id)
                            tipos_cuenta_habiles[cuenta[0]] = {};
                        }
                        if (tipos_cuenta_habiles[opcion.value]) {
                          new_elementos[opcion.value] = {};
                          new_elementos[opcion.value] = opcion;
                        }
                      });
                      let new_elementos_array = Object.entries(new_elementos);
                      elemento.elementos = [];
                      new_elementos_array.forEach((element) => {
                        elemento.elementos.push(element[1]);
                      });
                      elemento.editable = item[15][proceso][1];
                      aux.push(elemento);
                    } else {
                      elemento.editable = item[15][proceso][1];
                      aux.push(elemento);
                    }
                  } else {
                    elemento.editable = item[15][proceso][1];
                    aux.push(elemento);
                  }
                }
              });
            }
          }
        );
      }

      // console.log(aux);
      return aux;
    },
    title() {
      let aux = "";
      if (this.dataDestinSelected) {
        if (this.dataDestinSelected.tipo == "datos_personales") {
          aux = this.t("app.destin_my_data_form.edit_datos_personales");
        } else if (this.dataDestinSelected.tipo == "forma_entrega") {
          aux = this.t("app.destin_my_data_form.edit_medio_entrega");
        } else if (this.dataDestinSelected.tipo == "nueva_forma_entrega") {
          aux = this.t("app.destin_my_data_form.nuevo_medio_entrega");
        }
      }
      return aux;
    },
    sub_title() {
      let aux = "";
      if (this.dataDestinSelected) {
        aux = `${
          this.dataDestinSelected.data.nombre1
            ? this.dataDestinSelected.data.nombre1
            : ""
        } ${
          this.dataDestinSelected.data.nombre2
            ? this.dataDestinSelected.data.nombre2
            : ""
        } ${
          this.dataDestinSelected.data.apellido1
            ? this.dataDestinSelected.data.apellido1
            : ""
        } ${
          this.dataDestinSelected.data.apellido2
            ? this.dataDestinSelected.data.apellido2
            : ""
        }`;
      }
      return aux;
    },
    paisEditable() {
      let aux = { editable: true, pais_val: "" };
      if (this.procesoGeneral) {
        if (
          this.procesoGeneral == "cotizador" ||
          this.procesoGeneral == "misDatos"
        ) {
          aux = {
            editable: false,
            pais_val: this.transaccion.pais_moneda_destino_id,
          };
        }
      }
      return aux;
    },

    estadoBtn() {
      let aux = "inactive";
      if (this.estado_validaciones) {
        aux = "active";
      }
      return aux;
    },
  },
  methods: {
    ...mapMutations([
      "updateDesde",
      "updateListaElementosDom",
      "updateInfoNodos",
      "updateBloquearNavegacion",
      "updateRemitenteDestinatarios",
      "updateListaElementosDomSpPropiedad",
      "updateListaElementosDomSpElemento",
      "destinatarioDatosUnificados",
      "updateDataNewDestinatarioRegistrado",
      "setDestinatarioTemp",
      "updateRemitenteDestinatariosFormeEntrega",
      "setshowSpinner",
    ]),
    ...mapActions([
      "dibujarNodosNivel",
      "crearNodoInput",
      "cambioElementoVue",
      "tieneErroresNivelDetalle",
      "formaPagoRegistrar",
      "forma_entrega_update",
      "destinatario_update",
      "traer_regiones_v2",
      "traer_comunas_v2",
      "traer_bancos_pais_destino_v2",
      "traer_monedas_caja",
      "traer_regiones_caja_v2",
      "traer_comunas_caja",
      "validarTarjetaBeneficiario",
      "traer_bancos_caja",
    ]),

    async verificar_estado_validaciones() {
      let tieneErroresNivelX = await this.tieneErroresNivelDetalle({
        tipo_persona: "Destinatario",
        id_persona: "0",
        nivel: this.nivelActual,
        edit_destinatario: this.edit_destinatario,
      });
      let aux = !tieneErroresNivelX.resultado;
      this.estado_validaciones = aux;
    },
    backLocal() {
      this.updateRemitenteDestinatarios({});

      if (this.procesoGeneral == "misDatos") {
        this.$router.push({ name: "destinatariosDetalle", replace: true });
      } else if (this.menuSpDesde.desde == "cotizadorDestinatario") {
        this.$router.push({ name: this.menuSpDesde.desde, replace: true });
      } else if (this.menuSpDesde.desde == "ingresar_giro") {
        this.$router.push({ name: "ingresar_giro", replace: true });
      } else {
        this.$router.push({ name: "destinatariosDetalle", replace: true });
      }
    },
    async callEnvioSeguroModal() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: envioSeguroHome,
      });
      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },

    async traer_datos_editar() {
      this.setshowSpinner(true);
      for (
        let index = 0;
        index < this.configuracion.reglasFormularioDestinatario.length;
        index++
      ) {
        const item = this.configuracion.reglasFormularioDestinatario[index];

        let dato_existe = false;
        let pais_dest =
          this.remitente.destinatarios["0"].pais_moneda_destino_id.split(
            "_"
          )[0];

        // if (item[4] == "region") {
        //   if (this.datos_consultados[pais_dest]) {
        //     if (this.datos_consultados[pais_dest][item[4]]) {
        //       if (this.datos_consultados[pais_dest][item[4]]) {
        //         dato_existe = true;
        //       }
        //     }
        //   }

        //   if (!dato_existe) {
        //     await this.traer_regiones_v2(pais_dest);
        //   }
        // }
        // if (item[4] == "comuna_id") {
        //   let region = this.remitente.destinatarios["0"]["region"];
        //   if (this.datos_consultados[pais_dest]) {
        //     if (this.datos_consultados[pais_dest][item[4]]) {
        //       if (this.datos_consultados[pais_dest][item[4]][`${region}`]) {
        //         dato_existe = true;
        //       }
        //     }
        //   }

        //   if (!dato_existe) {
        //     await this.traer_comunas_v2({ pais: pais_dest, region: region });
        //   }
        // }

        if (this.dataDestinSelected.tipo == "forma_entrega") {
          let fe =
            this.remitente.destinatarios["0"].detalle_entrega["0"][
              "forma_entrega_id"
            ];
          if (fe == 1) {
            if (item[4] == "banco") {
              if (this.datos_consultados[pais_dest]) {
                if (this.datos_consultados[pais_dest][item[4]]) {
                  if (this.datos_consultados[pais_dest][item[4]]) {
                    dato_existe = true;
                  }
                }
              }

              if (!dato_existe) {
                await this.traer_bancos_pais_destino_v2({
                  pais: pais_dest,
                  form_ent: fe,
                });
              }
            }
          } else if (fe == 2) {
            if (item[4] == "moneda_caja") {
              if (this.datos_consultados[pais_dest]) {
                if (this.datos_consultados[pais_dest].moneda_caja) {
                  if (this.datos_consultados[pais_dest].moneda_caja) {
                    dato_existe = true;
                  }
                }
              }
              if (!dato_existe) await this.traer_monedas_caja(pais_dest);
              // console.log("traer_monedas_caja");
            }
            
            if (item[4] == "region_caja") {
              
              let banco_caja =
                this.remitente.destinatarios["0"].detalle_entrega["0"][
                  "banco_caja"
                ];
              
              if (this.datos_consultados[pais_dest]) {
                if (this.datos_consultados[pais_dest].region_caja) {
                  if (
                    this.datos_consultados[pais_dest].region_caja[
                      `${banco_caja}`
                    ]
                  ) {
                    dato_existe = true;
                  }
                }
              }
              
              if (!dato_existe) {
                this.setshowSpinner(true);
                await this.traer_regiones_caja_v2({
                  banco: banco_caja,
                  pais: pais_dest,
                });
                this.setshowSpinner(false);
              }
            }
            
          } else if (fe == 6) {
            if (item[4] == "banco_caja") {
              let dato_existe = false;

              if (!dato_existe) {
                this.setshowSpinner(true);
                await this.traer_bancos_pais_destino_v2({
                  pais: pais_dest,
                  form_ent: 6,
                });
                this.setshowSpinner(false);
              }
            }
          }
        }
      }
      this.setshowSpinner(false);
      this.cargarElementosDom();
    },

    async cambioElementoLocal(data) {
      this.verificar_estado_validaciones();

      let nombreDependiente = "";
      if (data.clasetienedependiente.length > 0) {
        nombreDependiente = data.clasetienedependiente.substring(17);
      }

      let variable_array = data.elemento.split("-");
      let variable = variable_array[variable_array.length - 1];

      let borrar_descendientes = false;
      if (variable == "pais_moneda_destino_id") {
        if (this.primeraCarga) {
          borrar_descendientes = false;
        } else {
          borrar_descendientes = true;
        }
      } else {
        borrar_descendientes = true;
      }

      if (variable == "pais_moneda_destino_id") {
        let dato_existe = false;
        let pais_dest = data.valor.split("_")[0];
        if (this.datos_consultados[pais_dest]) {
          if (this.datos_consultados[pais_dest].region) {
            dato_existe = true;
          }
        }

        if (!dato_existe) {
          this.setshowSpinner(true);
          await this.traer_regiones_v2(pais_dest);
          this.setshowSpinner(false);
        }
      }

      // if (variable == "region") {
      //   let pais_dest =
      //     this.remitente.destinatarios["0"].pais_moneda_destino_id.split(
      //       "_"
      //     )[0];
      //   let dato_existe = false;
      //   if (this.datos_consultados[pais_dest]) {
      //     if (this.datos_consultados[pais_dest].comuna_id) {
      //       if (this.datos_consultados[pais_dest].comuna_id[`${data.valor}`]) {
      //         dato_existe = true;
      //       }
      //     }
      //   }

      //   if (!dato_existe) {
      //     this.setshowSpinner(true);
      //     await this.traer_comunas_v2({
      //       pais: pais_dest,
      //       region: data.valor,
      //     });
      //     this.setshowSpinner(false);
      //   }
      // }

      if (variable == "forma_entrega_id") {
        let pais_dest =
          this.remitente.destinatarios["0"].pais_moneda_destino_id.split(
            "_"
          )[0];
        if (data.valor == 1) {
          let dato_existe = false;
          if (this.datos_consultados[pais_dest]) {
            if (this.datos_consultados[pais_dest].banco) {
              dato_existe = true;
            }
          }

          if (!dato_existe) {
            this.setshowSpinner(true);
            await this.traer_bancos_pais_destino_v2({
              pais: pais_dest,
              form_ent: data.valor,
            });
            this.setshowSpinner(false);
          }
        } else if (data.valor == 2) {
          this.setshowSpinner(true);
          await this.traer_monedas_caja(pais_dest);
          this.setshowSpinner(false);
        } else if (data.valor == 6) {
          let dato_existe = false;
          if (this.datos_consultados[pais_dest]) {
            if (this.datos_consultados[pais_dest].bco_wallet_cn) {
              dato_existe = true;
            }
          }

          if (!dato_existe) {
            this.setshowSpinner(true);
            await this.traer_bancos_pais_destino_v2({
              pais: pais_dest,
              form_ent: data.valor,
            });
            this.setshowSpinner(false);
          }
        }
      }

      
      if (variable == "banco_caja") {
        let pais_dest =
          this.remitente.destinatarios["0"].pais_moneda_destino_id.split(
            "_"
          )[0];
        let dato_existe = false;
        if (this.datos_consultados[pais_dest]) {
          if (this.datos_consultados[pais_dest].region_caja) {
            if (
              this.datos_consultados[pais_dest].region_caja[`${data.valor}`]
            ) {
              dato_existe = true;
            }
          }
        }
        // console.log(variable, data.valor, dato_existe);
        if (!dato_existe) {
          this.setshowSpinner(true);
          await this.traer_regiones_caja_v2({
            banco: data.valor,
            pais: pais_dest,
          });
          this.setshowSpinner(false);
        }
      }


      let dependiente_simple = nombreDependiente.split(",");

      dependiente_simple.forEach(async (dependiente) => {
        let cambioData = await this.cambioElementoVue({
          elemento: data.elemento,
          valor: data.valor,
          accion: data.accion,
          tipo_persona: data.tipo_persona,
          clasetienedependiente: `${data.clasetienedependiente.substring(
            0,
            17
          )}${dependiente}`,
          borrarValorHijo: borrar_descendientes,
          edit_destinatario: this.edit_destinatario,
        });

        let elemento = data.elemento.split("-");

        if (cambioData.cargarDataAlElementoDom) {
          let index = this.listaElementosDom.findIndex((item) => {
            return (
              `slct-${item.nombre_elemento}` ==
              cambioData.dataNuevaPasada.elemento
            );
          });

          if (index > -1) {
            this.updateListaElementosDomSpPropiedad({
              index: index,
              propiedad: "fireChange",
              valor: false,
            });
            this.updateListaElementosDomSpPropiedad({
              index: index,
              propiedad: "elementos",
              valor: cambioData.elementos.elementos,
            });
            this.updateListaElementosDomSpPropiedad({
              index: index,
              propiedad: "isFilledTag",
              valor: cambioData.isFilledTag,
            });
            this.updateListaElementosDomSpPropiedad({
              index: index,
              propiedad: "tieneError",
              valor: cambioData.tieneError,
            });
          }
        } else if (
          cambioData.tipo_elemento &&
          cambioData.tipo_elemento == "input"
        ) {
          let index = this.listaElementosDom.findIndex((item) => {
            return (
              item.identificador == cambioData.dataNuevaPasada.identificador
            );
          });

          if (index > -1) {
            this.updateListaElementosDomSpPropiedad({
              index: index,
              propiedad: "fireChange",
              valor: false,
            });
            this.updateListaElementosDomSpElemento({
              index: index,
              valor: cambioData.dataNuevaPasada,
            });
            setTimeout(() => {
              this.updateListaElementosDomSpPropiedad({
                index: index,
                propiedad: "fireChange",
                valor: true,
              });
            }, 500);
          }
        } else {
          let index = this.listaElementosDom.findIndex((item) => {
            return item.variable == elemento[3];
          });

          if (index > -1 && this.listaElementosDom[index]) {
            this.updateListaElementosDomSpPropiedad({
              index: index,
              propiedad: "tieneError",
              valor: cambioData.tieneError,
            });
          }
        }

        if (elemento[3] && elemento[3] == "pais_moneda_destino_id") {
          this.primeraCarga = false;
        }
      });
    },
    cargarElementosDom() {
      this.updateListaElementosDom([]);
      this.updateInfoNodos([]);

      this.dibujarNodosNivel({
        tipo_persona: "Destinatario",
        id_persona: "0",
        nivel: this.nivelActual,
        edit_destinatario: this.edit_destinatario,
      });
    },

    async continuarRegistro() {
      let tieneErroresNivelX = await this.tieneErroresNivelDetalle({
        tipo_persona: "Destinatario",
        id_persona: "0",
        nivel: this.nivelActual,
        edit_destinatario: this.edit_destinatario,
      });

      if (!tieneErroresNivelX.resultado) {
        if (this.nivelActual + 1 <= this.niveles) {
          this.nivelActual += 1;

          this.setshowSpinner(false);
          this.cargarElementosDom();
        } else {
          this.btnActive = false;

          this.updateDataNewDestinatarioRegistrado({
            nombre: `${this.remitente.destinatarios[0].nombre1} ${this.remitente.destinatarios[0].apellido1} ${this.remitente.destinatarios[0].apellido2}`,
          });

          let obj = {
            destinatario_id: this.dataDestinSelected.data.id,
            destinatario_persona: "0",
          };
          if (this.dataDestinSelected.tipo == "datos_personales") {
            this.destinatario_update({ id: this.dataDestinSelected.data.id });
          } else if (this.dataDestinSelected.tipo == "forma_entrega") {
            this.forma_entrega_update({
              destinatario_id: this.dataDestinSelected.data.id,
              destinatario_persona: "0",
              forma_entrega_id: this.dataDestinSelected.indexFE,
            });
          } else if (this.dataDestinSelected.tipo == "nueva_forma_entrega") {
            this.formaPagoRegistrar(obj);
          }
        }
      } else {
        this.setshowSpinner(false);
        console.log(tieneErroresNivelX);
        this.variable_con_error = "";
        this.listaElementosDom.forEach((element) => {
          element.tieneError = false;
        });

        tieneErroresNivelX.detalle.forEach((error) => {
          let aux = this.listaElementosDom.findIndex((elem) => {
            return elem.variable == error[1] && error[0] < 2;
          });
          if (aux > -1) {
            if (this.listaElementosDom[aux].tipo == "selectDir") {
              this.listaElementosDom[
                aux
              ].elementos.elementos[0].tieneError = true;
            }
            this.listaElementosDom[aux].tieneError = true;
          }
          if (this.variable_con_error == "") {
            this.scroll_to_error(error[1]);
            this.variable_con_error = error[1];
          }
        });
      }
    },
    scroll_to_error(variable_con_error) {
      this.$refs["contenido_editDesnatario"].scrollTo({
        top: this.$refs[variable_con_error][0].offsetTop,
        behavior: "smooth",
      });
    },
    valida_basico(data) {
      this.obj_valida_basico[data.variable] = data.valida;
      this.verificar_estado_validaciones();
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scroll_to_top = blur_sup_scroll(scrollTop);
      this.scroll_to_bottom = blur_inf_scroll(
        scrollTop,
        clientHeight,
        scrollHeight
      );
    },
  },
  watch: {
    async infoNodos() {
      if (this.infoNodos.length > 0 && this.enVista) {
        for (const element of this.infoNodos) {
          await this.crearNodoInput({
            elementoDom: element.elementoDom,
            tipo_persona: element.tipo_persona,
            id_persona: element.id_persona,
            variable: element.variable,
            pais: element.pais,
            edit_destinatario: this.edit_destinatario,
          });
        }
        setTimeout(() => {
          this.updateListaElementosDom(this.listaElementosDomTemp);
        }, 500);
      }
    },

    heigthScreen() {
      this.contenido_editDesnatario = contenido_style(
        this.heigthScreen,
        this.header,
        this.footer,
        this.plataforma == "web" ? 63 : 0
      );
    },
    respBeneficiarioModif() {
      if (this.respBeneficiarioModif.status) {
        this.setshowSpinner(false);
        if (this.respBeneficiarioModif.resp) {
          this.$router.push({
            name: "nuevoDestinatarioFinalizar",
            replace: true,
          });
        }
      }
    },
    resp_destinatario_update() {
      if (this.resp_destinatario_update.status) {
        this.setshowSpinner(false);
        if (this.resp_destinatario_update.resp) {
          this.$router.push({
            name: "destinMyDataForm_finalizar",
            replace: true,
          });
        }
      }
    },
    resp_forma_entrega_update() {
      if (this.resp_forma_entrega_update.status && this.enVista) {
        this.setshowSpinner(false);
        if (this.resp_forma_entrega_update.resp) {
          this.$router.push({
            name: "destinMyDataForm_finalizar",
            replace: true,
          });
        }
      }
    },
    resp_forma_entrega_reg() {
      if (this.resp_forma_entrega_reg.status && this.enVista) {
        this.setshowSpinner(false);
        if (this.resp_forma_entrega_reg.resp) {
          if (
            this.menuSpDesde.desde == "ingresar_giro" ||
            this.menuSpDesde.desde == "cotizadorDestinatario"
          ) {
            let destinatario = {
              accion: "seleccionar_transaccion",
              id_persona: this.resp_forma_entrega_reg.destinatario_id,
              forma_entrega_id:
                this.remitente.destinatarios["0"].detalle_entrega["0"]
                  .forma_entrega_id,
              tipo_cuenta:
                this.remitente.destinatarios["0"].detalle_entrega["0"]
                  .tipo_cuenta,
              banco:
                this.remitente.destinatarios["0"].detalle_entrega["0"]
                  .id_banco_destino,
              cuenta_corriente:
                this.remitente.destinatarios["0"].detalle_entrega["0"]
                  .numero_cuenta,
              nro_cuenta:
                this.remitente.destinatarios["0"].detalle_entrega["0"]
                  .numero_cuenta,
              motivo_transaccion: "",
              detalle_entrega_id: this.resp_forma_entrega_reg.fe_id,
            };
            this.validarTarjetaBeneficiario(destinatario);
          }

          this.$router.push({
            name: "destinMyDataForm_finalizar",
            replace: true,
          });
        }
      }
    },
  },
  ionViewWillEnter() {
    this.niveles = 0;
    this.nivelActual = 0;
    this.enVista = true;
    this.primeraCarga = true;
    this.btnActive = true;
    this.edit_destinatario = null;

    this.updateRemitenteDestinatarios({});
    this.setDestinatarioTemp(this.dataDestinSelected.data.id);
    this.updateDesde("beneficiarioedit");
    this.updateListaElementosDom([]);
    this.updateInfoNodos([]);

    if (this.dataDestinSelected.tipo == "datos_personales") {
      this.niveles = 1;
      this.nivelActual = 1;
      this.edit_destinatario = { editFE: false, indexFE: null };
      setTimeout(() => {
        this.traer_datos_editar();
      }, 500);
    } else if (this.dataDestinSelected.tipo == "forma_entrega") {
      this.niveles = 1;
      this.nivelActual = 2;
      this.edit_destinatario = {
        editFE: true,
        indexFE: this.dataDestinSelected.indexFE,
      };
      setTimeout(() => {
        this.traer_datos_editar();
      }, 500);
    } else if (this.dataDestinSelected.tipo == "nueva_forma_entrega") {
      this.niveles = 1;
      this.nivelActual = 2;
      this.edit_destinatario = {
        editFE: true,
        indexFE: "0",
      };
      this.registrando = true;

      setTimeout(() => {
        this.traer_datos_editar();
      }, 500);
    } else {
      this.niveles = Math.max.apply(
        Math,
        this.configuracion.reglasFormularioDestinatario.map((campo) => {
          return campo[3];
        })
      );
      this.nivelActual = 1;
    }
  },
  ionViewDidEnter() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.header = this.$refs["myHeader_editDesnatario"].offsetHeight;
        this.footer = this.$refs["footer_editDesnatario"].offsetHeight;
        this.cont_visible = true;
        this.contenido_editDesnatario = contenido_style(
          this.heigthScreen,
          this.header,
          this.footer,
          this.plataforma == "web" ? 63 : 0
        );
        this.blur_sup_editDesnatario = blur_sup_style(this.header);
        this.blur_inf_editDesnatario = blur_inf_style(this.footer);
      }, 100);

      setTimeout(() => {
        if (
          this.$refs["contenido_editDesnatario"].scrollHeight >
          this.$refs["contenido_editDesnatario"].clientHeight
        ) {
          this.scroll_to_bottom = false;
        }
      }, 100);
    });
  },
  ionViewWillLeave() {
    this.cont_visible = false;
    this.enVista = false;
    this.updateRemitenteDestinatarios({});
    this.updateListaElementosDom([]);
    this.updateInfoNodos([]);
    this.updateRemitenteDestinatariosFormeEntrega({
      destinatario_id: this.dataDestinSelected.data.id,
      valor: {},
    });
  },
};
</script>

<style>
.editDesnatarioTitle {
  margin-top: 0.8rem;
  text-align: center;
  color: #614ad3;
}
.editDesnatario_sub_Title {
  margin-top: 0.8rem;
  padding-bottom: 0.5rem;
  text-align: center;
  color: #614ad3;
}
.editDesnatarioBody {
  padding-right: 1rem;
  min-height: 18rem;
}
.editDesnatarioInputCont {
  margin-top: 0.5rem;
}
.editDesnatarioBtn {
  margin-top: 2rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}
.editDesnatarioBtn_fondo {
  margin-top: 2rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}
.editDesnatarioFooter {
  padding-top: 1.2rem;
  padding-bottom: 2rem;
}
</style>